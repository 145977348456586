
import { defineComponent } from 'vue'
import Lead from '@/components/Lead.vue'
import {
  CustomField as CustomFieldType,
  Lead as LeadType
} from '../interfaces/Lead/Lead'
import { Receipt } from '@/interfaces/Receipt'
import SearchPanel from '@/components/SearchPanel/SearchPanel.vue'
import ReceiptStatus from '@/components/ReceiptStatus.vue'

export default defineComponent({
  name: 'Home',
  props: {
    modelValue: {
      type: Object,
      default: null
    }
  },
  emits: ['update:modelValue'],
  components: {
    Lead,
    SearchPanel,
    ReceiptStatus
  },
  data: () => ({
    loading: {
      status: 1 as number,
      replyCount: 0 as number
    },
    search: {
      byNumber: 0 as number,
      filters: {
        customFields: [] as CustomFieldType[]
      }
    },
    cards: [] as LeadType[],
    selected: [] as number[],
    receiptBuilder: {
      receipts: [] as Receipt[]
    }
  }),
  computed: {
    filteredLeads (): LeadType[] {
      return this.cards.filter((lead: LeadType) => {
        if (this.search.filters.customFields.length > 0) {
          for (const customField of this.search.filters.customFields) {
            if (
              lead.customFields.find(
                (_cf: CustomFieldType) =>
                  _cf.value.toLowerCase() === customField.value.toLowerCase() &&
                  (_cf.type === '' ? _cf.type === customField.type : true)
              ) === undefined
            )
              return false
          }
        }
        if (this.search.byNumber > 9999) {
          const orderNum: CustomFieldType | undefined = lead.customFields.find(
            (customField: CustomFieldType) => customField.type === 'OrderNum'
          )
          if (
            typeof orderNum !== 'undefined' &&
            orderNum.value !== this.search.byNumber.toString()
          )
            return false
        }
        return true
      })
    }
  },
  methods: {
    getCards (reply = false) {
      this.loading.status = 1
      if (reply) this.loading.replyCount++

      fetch(`${process.env.VUE_APP_API_BASEURL}/leads/get`)
        .then((response) => response.json())
        .then((json: { success: number; data: LeadType[] }) => {
          this.loading.status = 0
          this.cards = json.data
          this.loading.replyCount = 0
        })
        .catch(() => (this.loading.status = -1))

      this.loading.status = 0
    },
    clicked (index: number) {
      const findedIndex: number = this.selected.findIndex(
        (_index) => _index === index
      )
      if (findedIndex !== -1) this.selected.splice(findedIndex, 1)
      else this.selected.push(index)
    },
    clickedCF (customField: CustomFieldType) {
      const findedIndex: number = this.search.filters.customFields.findIndex(
        (_customField: CustomFieldType) =>
          _customField.type === customField.type &&
          _customField.value === customField.value
      )
      if (findedIndex !== -1)
        this.search.filters.customFields.splice(findedIndex, 1)
      else {
        if (this.search.filters.customFields.length === 5)
          this.search.filters.customFields.splice(0, 1)
        this.search.filters.customFields.push({ ...customField })
      }
    },
    findLeadIndexById (id: number): number {
      return this.cards.findIndex((lead: LeadType) => lead.id === id)
    },
    addToReceiptBuilder () {
      for (const id of this.selected) {
        const leadIndex: number = this.findLeadIndexById(id)
        if (leadIndex >= 0) {
          const lead: LeadType = this.cards[leadIndex]
          this.receiptBuilder.receipts.push({
            lead,
            link: null
          } as Receipt)
          this.cards.splice(leadIndex, 1)
        }
      }
      this.selected = []
    },
    generateReceipts () {
      this.addToReceiptBuilder()
      for (
        let index = 0;
        index < this.receiptBuilder.receipts.length;
        index++
      ) {
        const receipt: Receipt = this.receiptBuilder.receipts[index]
        if (receipt.link) continue
        fetch(
          `${process.env.VUE_APP_API_BASEURL}/receipts/build/${receipt.lead.id}`
        )
          .then((response) => response.json())
          .then((json: { success: number; data: Receipt }) => {
            receipt.link = json.data.link
          })
      }
    },
    getReceiptStatus (index: number): string {
      const receipt: Receipt = this.receiptBuilder.receipts[index]
      if (receipt.link) return 'Чек сформирован'
      if (index - 1 >= 0) {
        const preventReceipt = this.receiptBuilder.receipts[index - 1]
        if (preventReceipt.link) return 'Формируется чек'
      } else return 'Формируется чек'
      return 'В очереди'
    }
  },
  created () {
    const model = { ...this.modelValue }
    model.navbar = {
      position: 'sticky',
      zIndex: 2,
      top: 0
    }
    this.$emit('update:modelValue', model)
  },
  mounted () {
    this.getCards()
  }
})
