
import { Icon } from '@/interfaces/Lead/Media'
import { defineComponent } from 'vue'
import CustomField from '@/mixins/CustomField'

export default defineComponent({
  name: 'CustomFieldChip',
  mixins: [CustomField],
  emits: ['click'],
  props: {
    color: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    }
  },
  methods: {
    getIcon (type: string): string | undefined {
      const icons: Icon[] = [
        {
          type: 'WhatIsIt',
          iconName: 'cake'
        },
        {
          type: 'WeightQuantity',
          iconName: 'list_alt'
        },
        {
          type: 'Taste',
          iconName: 'bakery_dining'
        },
        {
          type: 'Design',
          iconName: 'brush'
        },
        {
          type: 'Date',
          iconName: 'calendar_today'
        }
      ]
      const icon: Icon | undefined = icons.find(
        (icon: Icon) => type === icon.type
      )
      if (icon) return icon.iconName
      return undefined
    },
    clicked () {
      this.$emit('click', this.data)
    }
  }
})
