
import CustomFieldChip from '@/components/CustomFieldChip.vue'
import { CustomField as CustomFieldType } from '@/interfaces/Lead/Lead'
import { defineComponent, PropType } from 'vue'
import DisplayBreakpoints from '@/mixins/DisplayBreakpoints'

export default defineComponent({
  components: { CustomFieldChip },
  emits: ['update:modelValue'],
  mixins: [DisplayBreakpoints],
  props: {
    modelValue: {
      type: Array as PropType<CustomFieldType[]>,
      required: true
    }
  },
  data: () => ({
    addingForm: {
      mode: 'none' as string,
      tagInput: '' as string,
      dateInput: new Date()
    }
  }),
  watch: {
    'addingForm.dateInput' (value: Date) {
      this.add(this.dateFormat(value), 'Date')
    }
  },
  methods: {
    dateFormat: (date: Date) => date.toLocaleDateString(),
    clicked (index: number) {
      const _modelValue = this.modelValue.slice()
      _modelValue.splice(index, 1)
      this.$emit('update:modelValue', _modelValue)
    },
    addTag () {
      this.add(this.addingForm.tagInput)
      this.addingForm.tagInput = ''
    },
    add (value: string, pattern: null | string = null) {
      if (value === '') return
      value = value.trim()
      const type: string = pattern ?? this.tryPatterns(value)
      const _newMV = this.modelValue.slice()
      if (_newMV.length >= 5) _newMV.splice(0, _newMV.length - 4)
      _newMV.push({
        type,
        value
      } as CustomFieldType)
      this.$emit('update:modelValue', _newMV)
    },
    tryPatterns (value: string): string {
      if (this.tryWhatIsItPattern(value)) return 'WhatIsIt'
      if (this.tryWeightQuantityPattern(value)) return 'WeightQuantity'
      if (this.tryTastePattern(value)) return 'Taste'
      return ''
    },
    tryWhatIsItPattern (value: string): boolean {
      return this.whatIsItList
        .map((val) => val.toLowerCase())
        .includes(value.toLowerCase())
    },
    tryWeightQuantityPattern (value: string) {
      return new RegExp(/^\d+((,|\.)?\d*\sкг|\sшт)$/i).test(value)
    },
    tryTastePattern (value: string): boolean {
      return this.tasteList
        .map((val) => val.toLowerCase())
        .includes(value.toLowerCase())
    }
  },
  computed: {
    whatIsItList (): string[] {
      return [
        'Торт',
        'Ярусник',
        'Мусс',
        'Капкейки',
        'Макаронс СЕРДЦА',
        'Макаронс',
        'Кейкпопсы',
        'Трайфлы',
        'Кейкпопсы эскимо',
        'Открытка',
        'Дегустационный набор',
        'Свечки',
        'Холодные огни',
        'Топпер',
        'Сладкий набор',
        'Кулич',
        'Меренговый рулет',
        'Чай',
        'Зефир',
        'ПП Торт',
        'Драже'
      ]
    },
    tasteList (): string[] {
      return [
        'С ягодным миксом',
        'Шоколадный ганаш',
        'Без начинки',
        'С клубникой',
        'С малиной',
        'С вишней',
        'С карамелью',
        'С молочным шоколадным ганашем',
        'Карамельно-банановый шейк',
        'Сникерс',
        'Тропический смузи',
        'Пломбир с шоколадной крошкой',
        'Взбитый латте',
        'Мохито (мята-лимон)',
        'Маракуйя'
      ]
    }
  },
  name: 'CustomFieldsFilter'
})
