
import { CustomFieldTranslation } from '@/interfaces/Lead/Lead'
import CustomField from '@/mixins/CustomField'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomFieldBlock',
  mixins: [CustomField],
  methods: {
    translateType (type: string): string {
      const translate: CustomFieldTranslation | undefined = (
        [
          {
            type: 'WhatIsIt',
            value: 'Что'
          },
          {
            type: 'WeightQuantity',
            value: 'Вес/Шт'
          },
          {
            type: 'Taste',
            value: 'Вкус'
          },
          {
            type: 'Filling',
            value: 'Начинка'
          },
          {
            type: 'Design',
            value: 'Дизайн'
          },
          {
            type: 'Delivery',
            value: 'Доставка'
          },
          {
            type: 'Date',
            value: 'Дата'
          }
        ] as CustomFieldTranslation[]
      ).find(
        (translation: CustomFieldTranslation) => translation.type === type
      )
      if (!translate) return '(не известный тип)'
      return translate.value
    }
  }
})
