
import { Filters as FiltersType } from '@/interfaces/SearchPanel/Filters'
import { defineComponent, PropType } from 'vue'
import Filters from './Filters/Filters.vue'

export default defineComponent({
  components: { Filters },
  emits: ['update:filters', 'update:byNumber'],
  name: 'SearchPanel',
  props: {
    byNumber: {
      type: Number,
      default: undefined
    },
    filters: {
      type: Object as PropType<FiltersType>,
      default: undefined
    }
  },
  data () {
    return {
      byNum: this.byNumber?.toString() as string
    }
  },
  watch: {
    byNum (value: string) {
      if (value === '')
        this.byNumberUpdated()
    }
  },
  methods: {
    filtersUpdated (filters: FiltersType) {
      this.$emit('update:filters', filters)
    },
    byNumberUpdated () {
      let value: number = parseInt(this.byNum)
      if (isNaN(value)) value = 0
      this.$emit('update:byNumber', value)
    }
  },
  computed: {
    inputCheck () {
      return typeof this.byNumber !== 'undefined'
    }
  }
})
