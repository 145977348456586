
import { Receipt } from '@/interfaces/Receipt'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ReceiptStatus',
  props: {
    modelValue: {
      type: Object as PropType<Receipt>,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  }
})
