import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-panel" }
const _hoisted_2 = { class: "search-block" }
const _hoisted_3 = { class: "filters-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_input = _resolveComponent("va-input")!
  const _component_filters = _resolveComponent("filters")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.inputCheck)
        ? (_openBlock(), _createBlock(_component_va_input, {
            key: 0,
            class: "search-by-number",
            modelValue: _ctx.byNum,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.byNum) = $event)),
            onBlur: _ctx.byNumberUpdated,
            label: "Поиск по номеру",
            rules: [
          (v) =>
            !isNaN(v) ||
            'Лишние символы обрезаются сами, но лучше их не писать',
        ],
            clearable: ""
          }, null, 8, ["modelValue", "onBlur", "rules"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.filters)
        ? (_openBlock(), _createBlock(_component_filters, {
            key: 0,
            modelValue: _ctx.filters,
            "onUpdate:modelValue": _ctx.filtersUpdated
          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}