import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { VaCard, VaNavbar, VaButton, VaCardTitle, VaCardContent, VaNavbarItem, VuesticPluginsWithoutComponents, VaInnerLoading, VaIcon, VaChip, VaInput, VaDateInput, VaModal } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'

createApp(App).use(store).use(router).use(VuesticPluginsWithoutComponents)
  .component('va-card', VaCard)
  .component('va-icon', VaIcon)
  .component('va-chip', VaChip)
  .component('va-input', VaInput)
  .component('va-modal', VaModal)
  .component('va-navbar', VaNavbar)
  .component('va-button', VaButton)
  .component('va-date-input', VaDateInput)
  .component('va-card-title', VaCardTitle)
  .component('va-navbar-item', VaNavbarItem)
  .component('va-card-content', VaCardContent)
  .component('va-inner-loading', VaInnerLoading)
  .mount('#app')
