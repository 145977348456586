
import { CustomField as CustomFieldType } from '@/interfaces/Lead/Lead'
import { Filters } from '@/interfaces/SearchPanel/Filters'
import { defineComponent, PropType } from 'vue'
import CustomFieldsFilter from './CustomFieldsFilter.vue'

export default defineComponent({
  components: { CustomFieldsFilter },
  name: 'Filters',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object as PropType<Filters>,
      required: true
    }
  },
  methods: {
    customFieldsUpdated (customFields: CustomFieldType[]) {
      this.$emit(
        'update:modelValue',
        Object.assign(this.modelValue, { customFields })
      )
    }
  }
})
