import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "receipt-status" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_inner_loading = _resolveComponent("va-inner-loading")!
  const _component_va_input = _resolveComponent("va-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", {
      textContent: _toDisplayString(_ctx.status)
    }, null, 8, _hoisted_2),
    (_ctx.modelValue.link === null)
      ? (_openBlock(), _createBlock(_component_va_inner_loading, {
          key: 0,
          loading: "",
          class: "loading-bar"
        }))
      : _createCommentVNode("", true),
    (_ctx.modelValue.link !== null)
      ? (_openBlock(), _createBlock(_component_va_input, {
          key: 1,
          modelValue: _ctx.modelValue.link,
          readonly: ""
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}