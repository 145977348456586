import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-field-block" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "type",
      textContent: _toDisplayString(_ctx.translateType(_ctx.data.type))
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: "value",
      textContent: _toDisplayString(_ctx.data.value)
    }, null, 8, _hoisted_3)
  ]))
}