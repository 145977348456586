
import { defineComponent, PropType } from 'vue'
import { Lead, CustomField as CustomFieldType } from '@/interfaces/Lead/Lead'
import CustomFieldChip from './CustomFieldChip.vue'
import CustomFieldBlock from './CustomFieldBlock.vue'
import DisplayBreakpoints from '@/mixins/DisplayBreakpoints'

export default defineComponent({
  components: { CustomFieldChip, CustomFieldBlock },
  name: 'Lead',
  emits: ['click', 'click-cf'],
  mixins: [DisplayBreakpoints],
  props: {
    data: {
      type: Object as PropType<Lead>,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title (): string {
      const orderNum: CustomFieldType | undefined =
        this.getCFByType('OrderNum')
      if (typeof orderNum === 'undefined') return '(Нет номера заказа)'
      return `Заказ №${orderNum.value}`
    },
    pattern (): string[] {
      return [
        'WhatIsIt',
        'WeightQuantity',
        'Taste',
        'Filling',
        'Design',
        'Delivery',
        'Date'
      ]
    },
    mainFieldColors (): string[] {
      return ['success', 'primary', 'warning']
    },
    mainFieldsList (): CustomFieldType[] {
      return this.sortingFields(0, 3)
    },
    allFieldsList (): CustomFieldType[] {
      return this.sortingFields(0, this.pattern.length)
    },
    date (): CustomFieldType | undefined {
      const fieldsArr: CustomFieldType[] = this.sortingFields(
        this.pattern.length - 1,
        this.pattern.length
      )
      if (fieldsArr.length > 0) return fieldsArr[0]
      return undefined
    }
  },
  methods: {
    clicked () {
      this.$emit('click', {})
    },
    clickCustomField (customField: CustomFieldType) {
      this.$emit('click-cf', customField)
    },
    sortingFields (offset = 0, lastIndex = -1): CustomFieldType[] {
      if (lastIndex < -1) lastIndex = this.pattern.length
      const list: CustomFieldType[] = []
      for (
        let index = offset, count = 0;
        index < this.pattern.length && count < lastIndex;
        index++
      ) {
        const currentPositionField: CustomFieldType | undefined =
          this.data.customFields.find(
            (field: CustomFieldType) => field.type === this.pattern[index]
          )
        if (currentPositionField) {
          list.push(currentPositionField)
          count++
        }
      }
      return list
    },
    getCFByType (type: string): CustomFieldType | undefined {
      return this.data.customFields.find(
        (customField: CustomFieldType) => customField.type === type
      )
    }
  }
})
