
import { defineComponent } from 'vue'
import Navbar from '@/components/Navbar.vue'
export default defineComponent({
  name: 'App',
  data: () => ({
    routerView: {
      navbar: null
    }
  }),
  components: {
    Navbar
  }
})
