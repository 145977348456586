import { PropType } from 'vue'
import { CustomField } from '@/interfaces/Lead/Lead'

export default {
  props: {
    data: {
      type: Object as PropType<CustomField>,
      required: true
    }
  }
}
