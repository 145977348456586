import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_fields_filter = _resolveComponent("custom-fields-filter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_custom_fields_filter, {
      "onUpdate:modelValue": _ctx.customFieldsUpdated,
      "model-value": _ctx.modelValue.customFields
    }, null, 8, ["onUpdate:modelValue", "model-value"])
  ]))
}